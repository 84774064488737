import { useTranslation } from "react-i18next";
import { DefaultImageUser } from "~/old-app/constants";
import { cls } from "~/old-app/utils/helpers/cls";

export const FeaturedLinkCard = ({ item }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div
      className={cls(
        "flex items-center justify-center rounded-full bg-primary-50 h-32 md:h-40 w-32 md:w-40 p-2  overflow-hidden",
        {
          "cursor-pointer": !!item?.link,
        }
      )}
      onClick={() => {
        if (item?.link) window.open(item?.link, "_blank");
      }}
    >
      <div className="relative flex flex-col items-center justify-center min-w-0 break-words w-full gap-2">
        <div className="flex ">
          <img
            alt="..."
            src={item?.image || item?.icon || DefaultImageUser}
            className={`h-10 w-10 md:h-20 md:w-20  object-contain`}
            onError={(e) => {
              e.target.src = DefaultImageUser;
            }}
            loading="lazy"
          />
        </div>

        <h3 className="text-xs md:text-sm text-primary-400 font-medium text-center w-1/2 line-clamp-2">
          {item?.title}
        </h3>
      </div>
    </div>
  );
};
