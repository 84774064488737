export const SliderCard = ({ item }) => {
  return (
    <a
      href={item.link}
      key={item.id}
      target="_blank"
      className={`flex flex-col gap-3 items-center bg-opacity-20 text-green-500 text-lg font-bold text-center p-0  rounded-lg cursor-pointer`}
      style={{ background: `${item.color}` }}
    >
      <img
        src={item.image}
        className="w-full object-contain align-middle"
        // md:h-[30rem] lg:[h-35rem] h-[12rem]
        alt={item.name}
        loading="lazy"
      />
    </a>
  );
};
