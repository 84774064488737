import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import BranchCard from "../Cards/CardBranch";
import BrandCard from "../Cards/CardBrand";
import CategoryCard from "../Cards/CardCategory";
import ChildCard from "../Cards/CardChild";
import CardInfluencer from "../Cards/CardInfluencer";
import CardInfluencerHeighLights from "../Cards/CardInfluencerHeighLights";
import OfferCard from "../Cards/CardOffer";
import ProductCard from "../Cards/CardProduct";
import { FeaturedLinkCard } from "../Cards/featured-links-card";
import NoData from "../Cards/NoData";
import { OfferCollectionCard } from "../Cards/OfferCollectionCard";
import { SliderCard } from "../Cards/slider-card";
import Spinner from "../Spinner";

const Carousel = ({
  card,
  items,
  itemsCount,
  isLoading,
  center = false,
  breakpoints = {},
  size = "lg",
  showNavigation = true,
  titleTag,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { accessToken } = useAuthSlice();
  const isLoggedIn = !!accessToken;

  const [numSlides, setNumSlides] = useState(items?.length || 0);
  const [swiperInstance, setSwiperInstance] = useState(null);

  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update();
    }
  }, [itemsCount, swiperInstance]);

  useEffect(() => {
    setNumSlides(items?.length);
  }, [items]);

  const renderSlides = useCallback(
    (items) => {
      return items?.map((item, index) => (
        <SwiperSlide key={`slide-${index}`} className="">
          {card === "brand" ? (
            <BrandCard key={item.id} item={item} />
          ) : card === "child" ? (
            <ChildCard key={item.id} item={item} />
          ) : card === "sliders" ? (
            <SliderCard item={item} key={item.id} />
          ) : card === "category" ? (
            <CategoryCard key={item.id} item={item} />
          ) : card === "influencer" ? (
            <CardInfluencer key={item.id} item={item} />
          ) : card === "featured-links" ? (
            <FeaturedLinkCard key={item.id} item={item} />
          ) : card === "influencer-highlights" ? (
            <CardInfluencerHeighLights key={item.id} item={item} />
          ) : card === "branch" ? (
            <BranchCard key={item.id} item={item} />
          ) : card === "offer" ? (
            <OfferCard key={item.id} item={item} />
          ) : card === "offer-collections" ? (
            <OfferCollectionCard key={item.id} offer={item} />
          ) : card === "serviceBranch" ? (
            <BranchCard key={item.id} item={item} />
          ) : //<ServiceBranchCard key={item.id} item={item} />

          card === "featuredProduct" ? (
            <ProductCard
              // <ProductFeaturedCard
              size={size}
              key={item.id}
              showFav={isLoggedIn}
              item={item}
              itemId={item.id}
              titleTag={titleTag}
            />
          ) : card === "serviceProduct" ? (
            <ProductCard
              // <ServiceProductCard key={item.id} item={item} itemId={item.id} /> */}
              key={item.id}
              item={item}
              itemId={item.id}
              titleTag={titleTag}
            />
          ) : (
            <ProductCard
              key={item.id}
              showFav={isLoggedIn}
              item={item}
              itemId={item.id}
              titleTag={titleTag}
              // mini={card === "mini"}
            />
          )}
        </SwiperSlide>
      ));
    },
    [card, isLoggedIn]
  );

  const renderCarousel = useCallback(
    (slides) => {
      return (
        <Swiper
          dir={language === "ar" ? "rtl" : "ltr"}
          className="carousel container items-center px-0"
          modules={[Navigation, Pagination, A11y, Autoplay]}
          spaceBetween={50}
          slidesPerView={itemsCount}
          navigation={showNavigation}
          breakpoints={{
            320: {
              slidesPerView: 1.2,
              spaceBetween: 20,
            },
            480: {
              slidesPerView: 1.2,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 2.2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: itemsCount,
              spaceBetween: 20,
            },
            ...breakpoints,
          }}
          onSwiper={setSwiperInstance}
          // onSlideChange={() => {}}
        >
          {slides}
        </Swiper>
      );
    },
    [itemsCount, language, numSlides]
  );

  const renderContent = () => {
    if (isLoading) {
      return <Spinner />;
    } else if (items?.length > 0) {
      return renderCarousel(renderSlides(items));
    } else {
      return (
        <NoData
          title={t("global.noProductsTitle")}
          subtitle={t("global.noProductsSubtitle")}
        />
      );
    }
  };

  return (
    <>
      {renderContent()}
      {/* {!isLoading ? (
        items?.length > 0 ? (
          renderCarousel(renderSlides(items))
        ) : card === "brand" ? (
          <NoData
            title={t("global.noBrandsTitle")}
            subtitle={t("global.noBrandsSubtitle")}
          />
        ) : (
          <NoData
            title={t("global.noProductsTitle")}
            subtitle={t("global.noProductsSubtitle")}
          />
        )
      ) : (
        renderCarousel(
          [...Array(7)].map((e, i) => (
            <SwiperSlide key={i} className="">
              <GridSkeleton />
            </SwiperSlide>
          ))
        )
      )} */}
    </>
  );
};

export default Carousel;
