import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";

const CardInfluencerHeighLights = ({ item }) => {
  let {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div className="w-full">
      <div className="relative flex flex-col items-center justify-center min-w-0 break-words w-full">
        {/* TODO: when api is ready will see :) */}
        <Link
          to={`/`}
          key={item.id}
          className={`flex h-30 w-30 md:h-20 md:w-20`}
        >
          <img
            alt="..."
            src={item?.image || item?.icon}
            className="w-full h-full object-contain rounded-full  md:border-4 border-8 border-primary-100 transition-all duration-300 ease-in-out"
            loading="lazy"
          />
        </Link>
      </div>
    </div>
  );
};

export default CardInfluencerHeighLights;
