import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { DefaultImageUser } from "~/old-app/constants";

const CardInfluencer = ({ item }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div className="w-full">
      <div className="relative flex flex-col items-center justify-center min-w-0 break-words w-full cursor-pointer">
        <Link
          to={`/${language}/influencer/${item.code}`}
          key={item.id}
          className="flex h-20 w-20 md:w-32 md:h-32 rounded-full  relative"
        >
          <img
            alt="..."
            src={item?.image || item?.icon || DefaultImageUser}
            className=" object-contain rounded-full h-20 w-20  md:w-32 md:h-32"
            loading="lazy"
            onError={(e) => {
              e.target.src = DefaultImageUser;
            }}
          />

          <div className="absolute bg-primary-500 top-2 -end-1 rounded-full w-6 h-6 animate-bounce flex justify-center items-center z-10">
            <span className="text-white text-xs">
              {item?.products_count || 0}
            </span>
          </div>
          <div className="absolute inset-0 bg-gradient-to-r from-transparent to-black opacity-50 rounded-full clip-path-mycircle animate-pulse"></div>
        </Link>

        <blockquote className="relative py-4 pb-0">
          <h3 className="text-sm font-medium  text-secondary-950 text-center leading-3">
            {item?.name?.substring(0, 30)} {item?.name?.length > 30 && ".."}
          </h3>
        </blockquote>
      </div>
    </div>
  );
};

export default CardInfluencer;
