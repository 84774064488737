import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { DefaultImage } from "~/old-app/constants";

const cardChild = ({ item }) => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <Link
      to={`/${language}/categories/${item.slug || item.id}`}
      key={item.id}
      className={`flex flex-col gap-3 items-center bg-opacity-20 text-green-500 text-lg font-bold text-center p-5 rounded-lg`}
      style={{ background: `${item.color}` }}
    >
      <img
        src={item.icon || DefaultImage}
        alt={item.name}
        className="w-auto h-12 object-cover align-middle"
        loading="lazy"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = DefaultImage;
        }}
      />
      <h2 className="text-secondary-950 text-xs whitespace-nowrap">
        {item.name?.substring(0, 15)} {item.name?.length > 15 && ".."}
      </h2>
    </Link>
  );
};

export default cardChild;
