import { Link } from "@remix-run/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import StarRating from "~/old-app/components/Rating/index";
import { useCurrency } from "~/old-app/hooks/useCurrency";

const CardOffer = ({ item, imgW = "full", imgH = "h-52" }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { formatPrice } = useCurrency();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isExpired: false,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (!item?.discount_end_date) return;

      const endTime = new Date(item.discount_end_date).getTime();
      const now = new Date().getTime();
      const difference = endTime - now;

      if (difference <= 0) {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
          isExpired: true,
        });
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds, isExpired: false });
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    calculateTimeLeft(); // Initial calculation

    return () => clearInterval(timer);
  }, [item?.discount_end_date]);

  const primaryImage =
    item?.media?.find((media) => media.is_primary === 1) || {};

  const urlParam = item?.slug || item?.id;

  // Timer block component
  const TimerBlock = ({ value, label }) => (
    <div
      className="flex flex-col items-center bg-gray-100 rounded-lg w-16"
      style={{ padding: "0.1rem 0.1rem 2px 0.1rem" }}
    >
      <div className="text-sm font-bold text-gray-900">{value}</div>
      <div
        className="w-full  text-center text-xs p-1 text-gray-400 bg-white"
        style={{
          borderBottomLeftRadius: ".5rem",
          borderBottomRightRadius: ".5rem",
        }}
      >
        {label}
      </div>
    </div>
  );

  return (
    <div className="w-full">
      <div className="relative overflow-hidden bg-white rounded-lg shadow-sm border border-gray-100">
        <div className="flex flex-row">
          {/* Content Section - Left Side */}
          <div className="w-2/3 p-4 flex flex-col justify-between">
            {/* Title and Price Section */}
            <div className="space-y-3">
              <Link to={`/${language}/products/${urlParam}`}>
                <blockquote>
                  <Link to={`/${language}/products/${urlParam}`}>
                    <h5
                      className={`text-base text-secondary-950 leading-3  capitalize overflow-hidden line-clamp-2`}
                    >
                      {item?.name}
                    </h5>
                  </Link>

                  <div className="flex gap-2 items-center mb-1">
                    {item?.rating_average !== "undefined" && (
                      <StarRating
                        rating={parseFloat(item?.rating_average)}
                        locale={language}
                      />
                    )}
                    {item?.rating_count !== "undefined" && (
                      <span className="text-xxs text-typo-700">
                        ({item?.rating_count})
                      </span>
                    )}
                  </div>
                  <div
                    className={`text-sm flex gap-3 font-semibold text-secondary-950 leading-3 `}
                  >
                    {item?.on_sale ? (
                      <>
                        <span className="text-gray-2000 line-through">
                          {formatPrice(item?.price)}
                        </span>
                        <span className="whitespace-nowrap">
                          {formatPrice(item?.sale_price)}
                        </span>
                      </>
                    ) : (
                      <>{formatPrice(item?.price)}</>
                    )}
                  </div>
                </blockquote>
              </Link>

              {/* Timer Section */}
              {!timeLeft.isExpired && item?.discount_end_date && (
                <div className="flex gap-1">
                  <TimerBlock
                    value={String(timeLeft.days).padStart(2, "0")}
                    label="Days"
                  />
                  <TimerBlock
                    value={String(timeLeft.hours).padStart(2, "0")}
                    label="Hour"
                  />
                  <TimerBlock
                    value={String(timeLeft.minutes).padStart(2, "0")}
                    label="Min"
                  />
                  <TimerBlock
                    value={String(timeLeft.seconds).padStart(2, "0")}
                    label="Sec"
                  />
                  <div className="w-1/2"></div>
                </div>
              )}
            </div>
          </div>

          {/* Image Section - Right Side */}
          <div className="w-1/3 relative">
            <Link
              to={`/${language}/products/${urlParam}`}
              className="block relative h-48"
            >
              <img
                alt={primaryImage.name}
                src={primaryImage.url ? primaryImage.url : item?.image}
                className="w-full h-full object-contain"
                loading="lazy"
              />
            </Link>

            {/* Discount Tag */}
            {item?.on_sale && !timeLeft.isExpired && (
              <div className="absolute top-0 end-0 bg-error-500 text-white px-2 py-1 text-xs font-medium rounded">
                {item?.discount?.type === "percentage"
                  ? `${item?.discount?.value} %`
                  : `${item?.discount?.value}`}{" "}
                {t("product.off")}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOffer;
